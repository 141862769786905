import { Box, Button, Card, CardContent, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import PageHead from "../../../components/admin/PageHead";
import DownloadIcon from '@mui/icons-material/Download';
import dayjs from "dayjs";
const ViewLetter = () => {
    // const location = useLocation();
    // const { prefillData = {}} = location.state || {};
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const prefillData = query.get('data') ? JSON.parse(decodeURIComponent(query.get('data')!)) : null;
    const action = query.get('action');
   return(
    <>
         <Box sx={{ px: 2, py: 1.5, display:'flex', justifyContent:'space-between' }}>
            <PageHead primary='View Letter' />
        </Box>
        <Grid container columnSpacing={3}>
            <Grid item xs={12} lg={5}>
                <Card sx={{ mb: 2 }}>
                    <CardContent>
                        <Table>
                            <TableRow>
                                <TableCell sx={{p:'0 0 30px 0', width:'25%', border:0, fontWeight:600, color:'#656565'}} >State Name:</TableCell>
                                <TableCell sx={{p:'0 0 30px 0', width:'25%', border:0}}>{prefillData.state_name}</TableCell>
                                <TableCell sx={{p:'0 0 30px 0', width:'25%', border:0, fontWeight:600, color:'#656565'}} >Company Name:</TableCell>
                                <TableCell sx={{p:'0 0 30px 0', width:'25%', border:0}}>{prefillData.company_name}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{p:'0 0 30px 0', width:'25%', border:0, fontWeight:600, color:'#656565'}} >Region Name:</TableCell>
                                <TableCell sx={{p:'0 0 30px 0', width:'25%', border:0}}>{prefillData.region_name}</TableCell>
                                <TableCell sx={{p:'0 0 30px 0', width:'25%', border:0, fontWeight:600, color:'#656565'}} >District Name:</TableCell>
                                <TableCell sx={{p:'0 0 30px 0', width:'25%', border:0}}>{prefillData.district_name}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{p:'0', width:'25%', border:0, fontWeight:600, color:'#656565'}} >Taluka Name:</TableCell>
                                <TableCell sx={{p:'0', width:'25%', border:0}}>{prefillData.taluka_name}</TableCell>
                                <TableCell sx={{p:'0', width:'25%', border:0, fontWeight:600, color:'#656565'}} >Project Name:</TableCell>
                                <TableCell sx={{p:'0', width:'25%', border:0}}>{prefillData.village_name}</TableCell>
                            </TableRow>
                        </Table>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} lg={7}>
                <Card sx={{ mb: 2 }}>
                    <CardContent>
                        <Table>
                            <TableRow>
                                <TableCell sx={{p:'0 0 30px 0', width:'25%', border:0, fontWeight:600, color:'#656565'}} >Addressee:</TableCell>
                                <TableCell sx={{p:'0 0 30px 0', width:'25%', border:0}}>{prefillData.address}</TableCell>
                                <TableCell sx={{p:'0 0 30px 0', width:'25%', border:0, fontWeight:600, color:'#656565'}} >CC:</TableCell>
                                <TableCell sx={{p:'0 0 30px 0', width:'25%', border:0}}>{prefillData.cc}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{p:'0 0 30px 0', width:'25%', border:0, fontWeight:600, color:'#656565'}} >Category Name:</TableCell>
                                <TableCell sx={{p:'0 0 30px 0', width:'25%', border:0}}>
                                    <Box sx={{ maxWidth: '200px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', cursor:'pointer' }}>
                                        <Tooltip title={prefillData.category_name}>
                                            {prefillData.category_name}
                                        </Tooltip>
                                    </Box>
                                </TableCell>
                                <TableCell sx={{p:'0 0 30px 0', width:'25%', border:0, fontWeight:600, color:'#656565'}} >Reference Number:</TableCell>
                                <TableCell sx={{p:'0 0 30px 0', width:'25%', border:0}}>{prefillData.reference_number || '-'}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{p:'0', width:'25%', border:0, fontWeight:600, color:'#656565'}} >Created Date:</TableCell>
                                <TableCell sx={{p:'0', width:'25%', border:0}}>{dayjs(prefillData.created_at || '-').format('DD-MM-YYYY ')}</TableCell>
                                <TableCell sx={{p:'0', width:'25%', border:0, fontWeight:600, color:'#656565'}} >Subject:</TableCell>
                                <TableCell sx={{p:'0', width:'25%', border:0}}>
                                    <Box sx={{ maxWidth: '350px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', cursor:'pointer' }}>
                                        <Tooltip title={prefillData.subject}>
                                            {prefillData.subject}
                                        </Tooltip>
                                    </Box>
                                </TableCell>
                            </TableRow>
                        </Table>
                        
                    </CardContent>           
                </Card>
            </Grid>
        </Grid>
        <Grid item xs={12} lg={12}>
            <Card sx={{ mb: 2 }}>
                <CardContent sx={{padding:0}}>
                    <TableContainer>
                        <Table>
                            <TableHead sx={{ backgroundColor: '#F8FAFF', fontWeight: 'bold' }}>
                                <TableRow>
                                    <TableCell width={'200px'}>Letter Stage</TableCell>
                                    <TableCell width={'200px'}>Upload File</TableCell>
                                    <TableCell width={'300px'}>Remark</TableCell>
                                    <TableCell width={'200px'}>Date</TableCell>                                       
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {prefillData?.letter_files?.map((file:any) => (
                                    <TableRow key={file.id}>
                                        <TableCell >{file.type.name}</TableCell>
                                        <TableCell>
                                            <Button variant="outlined" color="primary" onClick={() => window.open(file.file_path, '_blank', 'noopener,noreferrer')} >
                                                {file.file_name} <DownloadIcon sx={{ml:1}}/>
                                            </Button>
                                        </TableCell>
                                        <TableCell>{file.remarks}</TableCell>
                                        <TableCell>{file.date ? new Date(file.date).toLocaleDateString() : '-'}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
            </Card>
        </Grid>    
       
        
    </>
   )
}
export default ViewLetter 