import { useSelector } from 'react-redux';
import { UserTypeWisePagesConfig } from '../constant';
import { RootState } from '../redux/store';
import { AuthUserState } from './dto/response';


var CryptoJS = require('crypto-js');

export const isLoggedIn = (state: AuthUserState) => {
    const { auth_token, expires_at, user } = state;
    //const currentTime = Date.now();
    //return !!user && !!auth_token && !!expires_at && expires_at > currentTime;
    return !!user && !!auth_token && !!expires_at
}

export const isAuthorizedPage = (userType: string, pathname:string) => {
  // const path = window.location.pathname;
  return UserTypeWisePagesConfig[userType].indexOf(pathname) > -1
}

export function encryptText(plainText: any) {
  if (!plainText) return ''
  let key = CryptoJS.enc.Utf8.parse('2b7e151628aed2a6abf7158809cf4f3c');
  let iv = CryptoJS.enc.Utf8.parse('3ad77bb40d7a3660');
  let config = { keySize: 128, iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 };
  let encPassword = CryptoJS.AES.encrypt(plainText, key, config);
  return encPassword.ciphertext.toString(CryptoJS.enc.Base64);
}

export const decryptText = (text: any) => {
  if (!text) return ''
  let key = CryptoJS.enc.Utf8.parse('2b7e151628aed2a6abf7158809cf4f3c');
  let iv = CryptoJS.enc.Utf8.parse('3ad77bb40d7a3660');
  let config = { keySize: 128, iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 };
  try {
      let encPassword = CryptoJS.AES.decrypt(text, key, config);
      return encPassword.toString(CryptoJS.enc.Utf8);
  } catch (err) {
      try {
          return JSON.parse(text)
      } catch (err1) {
          return text || ''
      }
  }
}

export const isObject = (obj: any) => typeof obj === 'object' && !Array.isArray(obj) && obj !== null

export const stableSort = <T>(array: readonly T[], comparator: (a: T, b: T) => number) => {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

export const getComparator = <Key extends keyof any>(
    order: any,
    orderBy: Key,
  ): (
    a: { [key in Key]: any },
    b: { [key in Key]: any },
  ) => number => {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  export const descendingComparator = <T>(a: T, b: T, orderBy: keyof T) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }


export const GetPermissions = (slug: string = '') => {
  const authUser = useSelector((state: RootState) => state.authUser);
  const path = window.location.pathname;
  const isSuperAdmin = authUser.roles?.some((role: { name: string }) => role.name === 'Super Admin');
  // if (isSuperAdmin) {
  //     return ['Add', 'Edit', 'Delete', 'List', 'generate_reference_no', 'generate_manual_reference_no', 'store_letter', 'status_change', 'export', 'edit_letter', 'block_unblock_letter', 'add_parent_reference', 'download', 'letter_view', 'cancel-letter'];
  // }
  let permission: any = [];
  let found = false;
  const iter = (menus: any) => {
    if (found) return; 
    menus.forEach((menu: any) => {
      if (menu.slug === slug || menu.url === path) {
        permission = menu.permissions || [];
        found = true; 
        return;
      }
      if (menu.sub_menus) iter(menu.sub_menus);
    });
  };

  if (authUser && authUser.menus) iter(authUser.menus);
  return permission;
}
