import { Add } from "@mui/icons-material"
import { Badge, styled, Box, Button, Paper, TableContainer, Table, TableRow, TableCell, Checkbox, TableBody, TablePagination, Avatar, Tooltip, Menu, MenuItem, Chip, IconButton, ListItemIcon, useMediaQuery, CircularProgress, DialogActions, DialogContent, DialogContentText, DialogTitle, Dialog, Typography, Grid, TextField, Popover } from '@mui/material'
import PageHead from "../../../../components/admin/PageHead"
import TableHeadList from "../../../../components/table/TableHeadList";
import { useEffect, useState } from "react";
import AddUserDialog from "./addUserDialog";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import ViewUserDialog from "./viewUserDialog";
import DeleteUserDialog from "./deleteUserDialog";
import { UserExportService, UserListService } from "../../../../utils/services/user.service";
import { StyledTableCell } from "../../../../components/StyledComponents";
import { RootState } from "../../../../redux/store";
import { useSelector } from "react-redux";
import { GetPermissions } from "../../../../utils/utils";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import React from "react";
import BlockIcon from '@mui/icons-material/Block';
import UserBlockUnblock from "./userBlockUnblockDialog";
import OfflinePinIcon from '@mui/icons-material/OfflinePin';
import IosShareIcon from '@mui/icons-material/IosShare';
import { showSnackbar } from '../../../../redux/reducer/snackbarSlice';
import { useDispatch} from 'react-redux';
const headCells: any = [
    {
        id: 'srNO',
        numeric: false,
        disablePadding: false,
        label: 'Sr. No.',
        width: 50,
        sortable: false
    },
    {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: 'User Name',
        width: 250,
        sortable: true
    },
    {
        id: 'userEmail',
        numeric: false,
        disablePadding: false,
        label: 'User Email',
        width: 250,
        sortable: false
    },
    {
        id: 'mobile_number',
        numeric: false,
        disablePadding: false,
        label: 'Mobile Number',
        width: 220,
        sortable: false
    },
    {
        id: 'roles',
        numeric: false,
        disablePadding: false,
        label: 'Role',
        width: 200,
        sortable: false
    },
    {
        id: 'action',
        numeric: false,
        disablePadding: false,
        label: 'Action',
        width: 50,
        sortable: false
    },
];

const UserMaster = () => {
    const dispatch = useDispatch();
    const [popoverRowId, setPopoverRowId] = useState<any>(null);
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [currentUser, setCurrentUser] = useState<any>(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
    const [userToDelete, setUserToDelete] = useState<any>(null);
    const [viewDialogOpen, setViewDialogOpen] = useState<boolean>(false);
    const [userToView, setUserToView] = useState<any>(null);
    const [users, setUsers] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(25);
    const [totalRows, setTotalRows] = useState<number>(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [sortBy, setSortBy] = useState('name');
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
    const [manageToBlock, setManageToBlock] = useState<any>(null);
    const [blockedStatus, setBlockedStatus] = useState<{ [key: string]: boolean }>({});
    const [blockUnblockDialogOpen, setblockUnblockDialogOpen] = useState<boolean>(false);
    const userRoles = useSelector((state: RootState) => state.authUser.roles);
    const isAdminUser = userRoles?.some((role: { name: string }) => role.name === 'Super Admin');
    useEffect(() => {        
        fetchUser();
    }, [page, rowsPerPage, searchTerm, sortOrder, sortBy]);
    const fetchUser = async () => {
        setLoading(true);
        const payload = {
            limit: rowsPerPage,
            offset:page*rowsPerPage,
            sort_by:sortBy,
            order_by: sortOrder,
            search: searchTerm,
        };
        const { data, code } = await UserListService(payload);
        if (code === 200 && data) {
            setUsers(data.data || []);
            setTotalRows(data.recordsTotal);
        }
        setLoading(false);
    };

    const handleOpenDialog = () => {
        setCurrentUser(null);
        setDialogOpen(true);
    };

    const handleOpenEditDialog = (user: any) => {
        setCurrentUser(user);
        setDialogOpen(true);    
        setAnchorEl(null);
         
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };
    const handleOpenViewDialog = (user: any) => {
        setUserToView(user);
        setViewDialogOpen(true);
    };

    const handleCloseViewDialog = () => {
        setViewDialogOpen(false);
    };

    const handleOpenDeleteDialog = (user: any) => {
        setUserToDelete(user);
        setDeleteDialogOpen(true);
        setAnchorEl(null);
    };

    const handleCloseDeleteDialog = () => {
        setDeleteDialogOpen(false);
    };
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
      };
    
      const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
      };
      const handleSuccess = () => {
        fetchUser(); 
    };
    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };
    const permissions = GetPermissions('user_master');
    const handleSort = (id: string, order: 'asc' | 'desc') => {
        setSortBy(id);
        setSortOrder(order);
    };
    const handleReset = () => {
        setSearchTerm('');
    }; 
    const handleClickPopover = (event: React.MouseEvent<HTMLButtonElement>, rowId: any) => {
        setAnchorEl(event.currentTarget);
        setPopoverRowId(rowId);
    };
    const handleClosePopover = () => {
        setAnchorEl(null);
        setPopoverRowId(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;     

    const handleOpenBlockUnblockDialog = (manage: any, type: string) => {
        if (!manage || !manage.id) {
            return;
        }
        setManageToBlock({ ...manage, type });
        setblockUnblockDialogOpen(true);
    };
    const handleBlockUnblock = () => {
        if (manageToBlock) {
            setBlockedStatus((prevState) => ({
                ...prevState,
                [manageToBlock.id]: manageToBlock.type === 'block'
            }));
        }
    };
    const handleCloseBlockUnblockDialog = () => {
        setblockUnblockDialogOpen(false);
    };
    const handleUserExport = async () => {
        const payload = {
            limit: rowsPerPage, 
            offset: page * rowsPerPage, 
            sort_by: 'name', 
            order_by: 'asc', 
            search: searchTerm, 
        };
        const data = await UserExportService(payload);
        if (data && data.data) {
            const fileUrl = data.data.filePath;
            const a = document.createElement('a');
            a.href = fileUrl;
            a.download = fileUrl.substring(fileUrl.lastIndexOf('/') + 1);
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            const message =  'file exported successfully';
            dispatch(showSnackbar({ message, type: 'success', open: true }));
        } else {
            console.error('Export failed: no file path received');
        }       
    };
    console.log("sdfgh", userRoles);
    
    return (
        <>
            <Box>
                <Box sx={{ px: 2, py: 1.5 }}>
                    <PageHead primary='User Master' />
                </Box>
                <Paper>
                    <Box sx={{ borderTopWidth: 1, borderRightWidth: 0, borderBottomWidth: 1, borderLeftWidth: 0, borderStyle: 'solid', borderColor: 'grey.400', px: 2, py: 1 }}>
                        <Grid container columnSpacing={3}>
                            <Grid item xs={12} lg={9} >
                                <Box sx={{ display: 'flex', gap:2 }}>
                                    {permissions.includes('Add') && (
                                        <Button variant='contained' startIcon={<Add />} onClick={handleOpenDialog}>Add User</Button>
                                    )}   
                                    {permissions.includes('User Export') && (
                                        <Button variant='contained' color="success" startIcon={<IosShareIcon />} onClick={handleUserExport}>Export</Button> 
                                    )}
                                </Box>

                            </Grid>
                            <Grid item xs={12} lg={3} display={'flex'} textAlign={'right'} alignItems={'center'}>
                                <TextField fullWidth
                                    label="Search"
                                    placeholder="Search here ..."
                                    variant="outlined"
                                    size="small"
                                    value={searchTerm}
                                    onChange={handleSearchChange}
                                />
                                <Button onClick={handleReset} variant="contained" color="info" sx={{ ml: 2}}>
                                    Reset
                                </Button>
                            </Grid>                            
                        </Grid>   
                    </Box>

                    <TableContainer sx={{ maxHeight: 600 }}>
                        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size='medium' stickyHeader aria-label="sticky table">
                            <TableHeadList headCells={headCells} onSort={handleSort} sortBy={sortBy} sortOrder={sortOrder} />
                            {permissions.includes('List') ? (
                            <TableBody>
                                    {users.length > 0 ? (
                                        users?.map((row, index) => (
                                            <TableRow key={row.id}>
                                                <StyledTableCell sx={{ whiteSpace: 'nowrap', borderRightWidth: 1, borderRightStyle: 'solid', borderRightColor: 'grey.300' }}>
                                                    {index + 1 + page * rowsPerPage}
                                                </StyledTableCell>
                                                <StyledTableCell sx={{ whiteSpace: 'nowrap', borderRightWidth: 1, borderRightStyle: 'solid', borderRightColor: 'grey.300' }}>
                                                    {row.name}
                                                </StyledTableCell>
                                                <StyledTableCell sx={{ whiteSpace: 'nowrap', borderRightWidth: 1, borderRightStyle: 'solid', borderRightColor: 'grey.300' }}>
                                                    {row.email}
                                                </StyledTableCell>
                                                <StyledTableCell sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRightWidth: 1, borderRightStyle: 'solid', borderRightColor: 'grey.300' }}>
                                                    {row.mobile_number}
                                                </StyledTableCell>
                                                <StyledTableCell sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRightWidth: 1, borderRightStyle: 'solid', borderRightColor: 'grey.300' }}>
                                                    {row.roles.map((role: { name: any; }) => role.name).join(', ')}
                                                </StyledTableCell>
                                                <StyledTableCell sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRightWidth: 1, borderRightStyle: 'solid', borderRightColor: 'grey.300' }}>
                                                    <Button aria-describedby={id} onClick={(e) => handleClickPopover(e, row.id)}>
                                                        <MoreVertIcon />
                                                    </Button>
                                                    {popoverRowId === row.id && (
                                                        <Popover id={id} open={open} anchorEl={anchorEl} onClose={handleClosePopover} anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }}>
                                                            <Box sx={{display:'flex', flexDirection:'column', gap:2}}>
                                                                {permissions.includes('Edit') && ( 
                                                                    <Box display={'flex'} justifyContent={'space-between'} >  
                                                                        <Typography variant="h6" component="h6" fontWeight={600} color={"#434343"} fontSize={'15px'} mr={2}>Edit</Typography> 
                                                                        <Button size="small" variant="contained"  title="Edit" onClick={() => handleOpenEditDialog(row)} style={{ cursor: 'pointer', marginRight:10, width:'30px' }}>
                                                                                <ModeEditOutlinedIcon sx={{fontSize:'16px'}} />
                                                                        </Button>
                                                                    </Box>
                                                                )} 
                                                                {permissions.includes('Delete') && (
                                                                    <Box display={'flex'} justifyContent={'space-between'} >  
                                                                        <Typography variant="h6" component="h6" fontWeight={600} color={"#434343"} fontSize={'15px'} mr={2}>Delete</Typography> 
                                                                        <Button size="small" color="error" variant="contained"  title="Delete" onClick={() => handleOpenDeleteDialog(row)} style={{ cursor: 'pointer', marginRight:10, width:'30px' }}>
                                                                            <DeleteOutlineOutlinedIcon sx={{fontSize:'16px'}} />
                                                                        </Button>
                                                                    </Box>    
                                                                )}
                                                                {permissions.includes('Block/Unblock User') && (
                                                                    <>
                                                                        {row.is_blocked === 0 ? (
                                                                            <Box display={'flex'} justifyContent={'space-between'} >  
                                                                                <Typography variant="h6" component="h6" fontWeight={600} color={"#434343"} fontSize={'15px'} mr={2}>Block</Typography>
                                                                                <Button
                                                                                    size="small"
                                                                                    color="warning"
                                                                                    variant="contained"
                                                                                    title="Block"
                                                                                    onClick={() => handleOpenBlockUnblockDialog(row, 'block')}
                                                                                    style={{ cursor: 'pointer', marginRight: 10, width: '30px' }}
                                                                                >
                                                                                    <BlockIcon sx={{ fontSize: '16px' }} />
                                                                                </Button>
                                                                            </Box>
                                                                            ) : (
                                                                            <Box display={'flex'} justifyContent={'space-between'} >  
                                                                                <Typography variant="h6" component="h6" fontWeight={600} color={"#434343"} fontSize={'15px'} mr={2}>Unblock</Typography>
                                                                                <Button
                                                                                    size="small"
                                                                                    color="success"
                                                                                    variant="contained"
                                                                                    title="Unblock"
                                                                                    onClick={() => handleOpenBlockUnblockDialog(row, 'unblock')}
                                                                                    style={{ cursor: 'pointer', marginRight: 10, width: '30px' }}
                                                                                >
                                                                                    <OfflinePinIcon sx={{ fontSize: '16px' }} />
                                                                                </Button>
                                                                            </Box>    
                                                                        )}
                                                                    </>
                                                                )}
                                                            </Box>
                                                        </Popover> 
                                                    )}
                                               
                                                </StyledTableCell>
                                            </TableRow>
                                        ))
                                        ) : (
                                            <TableRow>
                                                <StyledTableCell colSpan={5} align="center">
                                                    {loading ? (
                                                        <CircularProgress size={24} />
                                                    ) : (
                                                        <Typography variant="body2" color="textSecondary">
                                                            No users found.
                                                        </Typography>
                                                    )}
                                                </StyledTableCell>
                                            </TableRow>
                                        )}
                            </TableBody>
                            ) : (
                                <TableRow>
                                    <StyledTableCell colSpan={6} align="center" sx={{p:1}}>
                                        <BlockIcon sx={{fontSize:'40px', color:"#0288d1"}}/>
                                        <Typography variant="h5" color="#0288d1" fontWeight={300}>
                                           Sorry, you cannot view the page.
                                        </Typography>
                                        <Typography variant="body1" color="error" fontWeight={300} pt={1}>
                                           No permission to access this page
                                        </Typography>
                                    </StyledTableCell>
                                </TableRow>
                            )}                            
                        </Table>
                    </TableContainer>    
                    <TablePagination
                    rowsPerPageOptions={[10, 25, 50]}
                    component="div"
                    count={totalRows}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    />                                 
                </Paper>
             </Box>
            {dialogOpen &&  <AddUserDialog dialogOpen={dialogOpen}  handleCloseDialog={handleCloseDialog} currentUser={currentUser} handleSuccess={handleSuccess} />} 
            {userToView && (
                <ViewUserDialog dialogOpen={viewDialogOpen} handleCloseDialog={handleCloseViewDialog} />
            )}
           <DeleteUserDialog dialogOpen={deleteDialogOpen} userToDelete={userToDelete} handleClose={handleCloseDeleteDialog} handleSuccess={handleSuccess}/>
           <UserBlockUnblock dialogOpen={blockUnblockDialogOpen} manageToBlock={manageToBlock} handleClose={handleCloseBlockUnblockDialog} handleSuccess={handleSuccess} handleBlockUnblock={handleBlockUnblock}/>
        </>
    )
}
export default UserMaster