import { Add } from "@mui/icons-material"
import { Badge, styled, Box, Button, Paper, TableContainer, Table, TableRow, TableCell, Checkbox, TableBody, TablePagination, Avatar, Tooltip, Menu, MenuItem, Chip, IconButton, ListItemIcon, useMediaQuery, CircularProgress, DialogActions, DialogContent, DialogContentText, DialogTitle, Dialog, Typography, Grid, TextField, FormControlLabel, Accordion, AccordionSlots, Fade, AccordionSummary, AccordionDetails, Autocomplete, FormControl, Popover } from '@mui/material'
import PageHead from "../../../../components/admin/PageHead"
import TableHeadList from "../../../../components/table/TableHeadList";
import { useEffect, useMemo, useState } from "react";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import { ProjectExportService, ProjectListService } from '../../../../utils/services/project.service';
import AddProjectDialog from "./addProjectDialog";
import DeleteProjectDialog from "./deleteProjectDialog";
import { StyledTableCell } from "../../../../components/StyledComponents";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import BulkUploadDialog from "./bulkUploadDialog";
import React from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { StateListService } from "../../../../utils/services/state.service";
import { FilterListService } from "../../../../utils/services/filter.service";
import { RootState } from "../../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { GetPermissions } from "../../../../utils/utils";
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import BlockIcon from '@mui/icons-material/Block';
import { showSnackbar } from "../../../../redux/reducer/snackbarSlice";
import IosShareIcon from '@mui/icons-material/IosShare';
const headCells: any = [
    {
        id: 'srNo',
        numeric: false,
        disablePadding: false,
        label: 'Sr. No.',
        width: 150,
        sortable: false
    },
    {
        id: 'states',
        numeric: false,
        disablePadding: false,
        label: 'State',
        width: 200,
        sortable: false
    },
    {
        id: 'company',
        numeric: false,
        disablePadding: false,
        label: 'Company',
        width: 600,
        sortable: false
    },
    {
        id: 'region',
        numeric: false,
        disablePadding: false,
        label: 'Region',
        width: 600,
        sortable: false
    },
    {
        id: 'district',
        numeric: false,
        disablePadding: false,
        label: 'District',
        width: 600,
        sortable: false
    },
    {
        id: 'taluka',
        numeric: false,
        disablePadding: false,
        label: 'Taluka',
        width: 600,
        sortable: false
    },
    {
        id: 'project_id',
        numeric: false,
        disablePadding: false,
        label: 'Project Id',
        width: 600,
        sortable: false
    },
    {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: 'Project',
        width: 600,
        sortable: true
    },
    {
        id: 'action',
        numeric: false,
        disablePadding: false,
        label: 'Action',
        width: 50,
        sortable: false
    },
];

const ProjectMaster = () => {
    const dispatch = useDispatch();
    const [popoverRowId, setPopoverRowId] = useState<any>(null);
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [error, setError] = useState('');
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [currentProject, setCurrentProject] = useState<any>(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
    const [bulkUploadDialogOpen, setBulkUploadDialogOpen] = useState<boolean>(false);
    const [projectToDelete, setProjectToDelete] = useState<any>(null);
    const [project, setProject] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(25);
    const [totalRows, setTotalRows] = useState<number>(0);
    const [searchTerm, setSearchTerm] = useState<string>(''); 
    const [states, setStates] = useState<any[]>([]);   
    const [companies, setCompanies] = useState<any[]>([]);
    const [regions, setRegions] = useState<any[]>([]);
    const [districts, setDistricts] = useState<any[]>([]);
    const [talukas, setTalukas] = useState<any[]>([]);
    const [expanded, setExpanded] = React.useState(false);
    const [sortBy, setSortBy] = useState('name');
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
    const userMapping = useSelector((state: RootState) => state.authUser.user_mapping);
    const userDetails = useSelector((state: RootState) => state.authUser.user);    
    const userRoles = useSelector((state: RootState) => state.authUser.roles);
    const isDistrictUser = userRoles?.some((role: { name: string }) => role.name === 'District User');
    const initialRegion = userMapping?.[0]?.region || '';
    const initialDistrict = userMapping?.[0]?.district || '';
    const initialCompany = companies.find((company: { value: number }) => company.value === userDetails?.company_id)?.label || '';   
    const initialState = states.find((state: { id: number }) => state.id === userDetails?.state_id)?.name || '';
    const regionsOptions = useMemo(() => 
        (userMapping || []).map((mapping: { region: any; region_id: any; }) => ({
            label: mapping.region,
            value: mapping.region_id,
        })),
        [userMapping]
    );
    const districtsOptions = useMemo(() =>   (userMapping || []).map((mapping: { district: any; district_id: any; }) => ({
        label: mapping.district,
            value: mapping.district_id,
        })), 
        [userMapping]
    );
    const [selectedIds, setSelectedIds] = useState({
        state_id: undefined as number | undefined,
        company_id: undefined as number | undefined,
        region_id: undefined as number | undefined,
        district_id: undefined as number | undefined,
        taluka_id: undefined as number | undefined,
    });
    const [formValues, setFormValues] = useState({
        state: '',
        company: '',
        region: regionsOptions,
        district: districtsOptions,
        taluka: '',
    });
    useEffect(() => {       
        fetchProjects();
    }, [page, rowsPerPage, searchTerm, sortOrder, sortBy]);
    const fetchProjects = async () => {
        setLoading(true);
        const payload = {
            limit: rowsPerPage,
            offset: page * rowsPerPage,
            sort_by: sortBy,
            order_by: sortOrder,
            search: searchTerm,
                ...selectedIds
        };
        const { data, code } = await ProjectListService(payload);
        if (code === 200 && data) {
            setProject(data.data);
            setTotalRows(data.recordsTotal);
        }
        setLoading(false);
    };
    const handleOpenDialog = () => {
        setCurrentProject(null);
        setDialogOpen(true);
    };

    const handleOpenEditDialog = (project: any) => {
        setCurrentProject(project);
        setDialogOpen(true);      
    };
    const handleOpenDeleteDialog = (project: any) => {
        setProjectToDelete(project);
        setDeleteDialogOpen(true);
    };

    const handleCloseDeleteDialog = () => {
        setDeleteDialogOpen(false);
    };
    const handleCloseDialog = () => {
        setDialogOpen(false);
    };
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
      };
    
      const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
      };
    const handleSuccess = () => {
        fetchProjects(); 
    };
    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };
    const handleOpenBulkUploadDialog = () => {
        setBulkUploadDialogOpen(true);     
    };
    const handleCloseBulkUploadDialog = () => {
        setBulkUploadDialogOpen(false);
    };

    const handleExpansion = () => {
        setExpanded((prevExpanded) => !prevExpanded);
    };

    useEffect(() => {        
        fetchStates();
    }, []);
    const fetchStates = async () => {
        setLoading(true);
        const payload = {
            limit: 100,
            offset: 0,
            sort_by: 'name',
            order_by: 'asc',
            search: ''
        };
        const { data, code } = await StateListService(payload);
        if (code === 200 && data) {
            setStates(data.data);
        }
        setLoading(false);
    };

    const getDropdownList = async (type: string, id?: number) => {
        setLoading(true);
        const payload = {
            type: type,
            id: id,
        };
        const { data, code } = await FilterListService(payload);
        if (data && code === 200) {
            if (type === 'state') {
                setCompanies(data.data);
            } else if (type === 'company') {
                setRegions(data.data);
            } else if (type === 'region') {
                setDistricts(data.data);
            } else if (type === 'district') {
                setTalukas(data.data);
            } {}
        }
        setLoading(false);
    };
    
    const handleInputChange = (field: string, value: string) => {
        setFormValues((prevValues) => ({
            ...prevValues,
            [field]: value,
        }));
    };

    const handleChange = (type: string, newValue: any | null, field: string) => {
        const idMap: { [key: string]: number | undefined } = {
            state: states.find((state: any) => state.name === newValue)?.id,
            company: companies.find((company: any) => company.label === newValue)?.value,
            region: regions.find((region: any) => region.label === newValue)?.value,
            district: districts.find((district: any) => district.label === newValue)?.value,
            taluka: talukas.find((taluka: any) => taluka.label === newValue)?.value,
        };
    
        setFormValues({ ...formValues, [type]: newValue || '' });
    
        const id = idMap[type];
        if (id !== undefined) {
            setSelectedIds({ ...selectedIds, [`${type}_id`]: id });
            getDropdownList(type, id);
        }

        if (type === 'state') {
            setFormValues((prevValues) => ({
                ...prevValues,
                company: '', 
                region: '',  
                district:'',
                taluka:''
            }));
            setSelectedIds((prevIds) => ({
                ...prevIds,
                company_id: undefined, 
                region_id: undefined, 
                district_id:undefined,
                taluka_id:undefined
            }));
        } else if (type === 'company') {
            setFormValues((prevValues) => ({
                ...prevValues,
                region: '', 
                district:'',
                taluka:''
            }));
            setSelectedIds((prevIds) => ({
                ...prevIds,
                region_id: undefined,
                district_id:undefined,
                taluka_id:undefined
            }));
        }else if (type === 'region') {
            setFormValues((prevValues) => ({
                ...prevValues,
                district:'',
                taluka:''
            }));
            setSelectedIds((prevIds) => ({
                ...prevIds,
                district_id:undefined,
                taluka_id:undefined
            }));
        }else if (type === 'district') {
            setFormValues((prevValues) => ({
                ...prevValues,
                taluka:''
            }));
            setSelectedIds((prevIds) => ({
                ...prevIds,
                taluka_id:undefined
            }));
        }


        if (newValue && typeof newValue === 'object') {
            if (field === 'region') {
                setSelectedIds((prevIds) => ({
                    ...prevIds,
                    region_id: (newValue as { value: number }).value,
                }));
            }
            if (field === 'district') {
                setSelectedIds((prevIds) => ({
                    ...prevIds,
                    district_id: (newValue as { value: number }).value,
                }));
            }
        }
    };
    
    const handleSubmit = () => {
        const { state, company, region, district, taluka } = formValues;
        if (!state && !company && !region && !district && !taluka ) {
          setError('Please select fields');
        } else {
          setError(''); 
          fetchProjects();
        }
    };
    const handleReset = () => {
        setFormValues({
            state: '',
            company: '',
            region:'',
            district:'',
            taluka:''
        });
        setSelectedIds({
            state_id: undefined,
            company_id: undefined,
            region_id:undefined,
            district_id:undefined,
            taluka_id:undefined
        });
        fetchProjects()
        window.location.reload()
    };
    const handleSearchReset = () => {
        setSearchTerm('');
    } 
    const permissions = GetPermissions('project_master');
    const handleSort = (id: string, order: 'asc' | 'desc') => {
        setSortBy(id);
        setSortOrder(order);
    };
    useEffect(() => {
        if (initialState) {
            setFormValues((prevValues) => ({
                ...prevValues,
                state: initialState,
            }));
            const initialStateId = states.find((state: { name: string }) => state.name === initialState)?.id;
            setSelectedIds((prevIds) => ({
                ...prevIds,
                state_id: initialStateId,
            }));
        }
    
        if (initialCompany) {
            setFormValues((prevValues) => ({
                ...prevValues,
                company: initialCompany,
            }));
            const initialCompanyId = companies.find((company: { label: string }) => company.label === initialCompany)?.value;
            setSelectedIds((prevIds) => ({
                ...prevIds,
                company_id: initialCompanyId,
            }));
        }
    
        if (initialRegion) {
            setFormValues((prevValues) => ({
                ...prevValues,
                region: regionsOptions.find((option: { label: any }) => option.label === initialRegion) || null,
            }));
            const initialRegionId = regionsOptions.find((option: { label: string }) => option.label === initialRegion)?.value;
            setSelectedIds((prevIds) => ({
                ...prevIds,
                region_id: initialRegionId,
            }));
        }
    
        if (initialDistrict) {
            setFormValues((prevValues) => ({
                ...prevValues,
                district: districtsOptions.find((option: { label: any }) => option.label === initialDistrict) || null,
            }));
            const initialDistrictId = districtsOptions.find((option: { label: string }) => option.label === initialDistrict)?.value;
            setSelectedIds((prevIds) => ({
                ...prevIds,
                district_id: initialDistrictId,
            }));
        }
    }, [initialState, initialCompany, initialRegion, regionsOptions, initialDistrict, districtsOptions, userMapping, isDistrictUser]);
    
    useEffect(() => {
        if (userDetails?.state_id) {
            getDropdownList('state', userDetails.state_id);
        }
    }, [userDetails?.state_id]);
    
    useEffect(() => {
        if (selectedIds.region_id) {
            getDropdownList('region', selectedIds.region_id);
        }
    }, [selectedIds.region_id]);
    useEffect(() => {
        if (selectedIds.district_id) {
            getDropdownList('district', selectedIds.district_id);
        }
    }, [selectedIds.district_id]);
      
    const sampleDownload = () => {
        const fileUrl = 'https://correspondence-s3.choiceconsultancy.co.in/UAT/Letters/SampleFileToUpload20240730_210036.xlsx';
        window.location.href = fileUrl;
    }
    const handleClickPopover = (event: React.MouseEvent<HTMLButtonElement>, rowId: any) => {
        setAnchorEl(event.currentTarget);
        setPopoverRowId(rowId);
    };
    const handleClosePopover = () => {
        setAnchorEl(null);
        setPopoverRowId(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;       
    const handleProjectExport = async () => {
        const payload = {
            limit: rowsPerPage, 
            offset: page * rowsPerPage, 
            sort_by: 'name', 
            order_by: 'asc', 
            search: searchTerm, 
        };
        const data = await ProjectExportService(payload);
        if (data && data.data) {
            const fileUrl = data.data.filePath;
            const a = document.createElement('a');
            a.href = fileUrl;
            a.download = fileUrl.substring(fileUrl.lastIndexOf('/') + 1);
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            const message =  'file exported successfully';
            dispatch(showSnackbar({ message, type: 'success', open: true }));
        } else {
            console.error('Export failed: no file path received');
        }       
    };
    return (
        <>
            <Box>
                <Box sx={{ px: 2, py: 1.5 }}>
                    <PageHead primary='Project Master' />
                </Box>
                <Accordion expanded={expanded}
                    onChange={handleExpansion}
                    slots={{ transition: Fade as AccordionSlots['transition'] }}
                    slotProps={{ transition: { timeout: 400 } }}
                    sx={{
                    '& .MuiAccordion-region': { height: expanded ? 'auto' : 0 },
                    '& .MuiAccordionDetails-root': { display: expanded ? 'block' : 'none' },
                    }}
                >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
                        <Typography>Filter</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container columnSpacing={3}>
                                <Grid item xs={12} lg={3} sx={{ mt: 2 }}>
                                    <FormControl fullWidth required>
                                        <Autocomplete
                                            size='small'
                                            value={formValues.state}
                                            onChange={(event: any, newValue: string | null) => handleChange('state', newValue, 'state')}
                                            inputValue={formValues.state}
                                            onInputChange={(event, newInputValue) => handleInputChange('state', newInputValue)}
                                            options={states.map((state: any) => state.name)}
                                            disableClearable
                                            freeSolo={false}
                                            disabled={initialState}
                                            renderInput={(params) => (
                                                <TextField 
                                                    {...params} 
                                                    label="States"
                                                
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} lg={3} sx={{ mt: 2 }}>
                                    <FormControl fullWidth required>
                                        <Autocomplete
                                            size='small'
                                            value={formValues.company}
                                            onChange={(event: any, newValue: string | null) => handleChange('company', newValue, 'company')}
                                            inputValue={formValues.company}
                                            onInputChange={(event, newInputValue) => handleInputChange('company', newInputValue)}
                                            options={companies.map((company: any) => company.label)}
                                            disableClearable
                                            freeSolo={false}
                                            disabled={initialCompany}
                                            renderInput={(params) => (
                                                <TextField 
                                                    {...params} 
                                                    label="Company"
                                                
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} lg={3} sx={{ mt: 2 }}>
                                    <FormControl fullWidth required>
                                        <Autocomplete
                                            size='small'
                                            // value={formValues.region}
                                            value={ regionsOptions.length === 1 ? regionsOptions[0] : formValues.region}
                                            onChange={(event: any, newValue: any | null) => handleChange('region', newValue, 'region')}
                                            inputValue={formValues.region ? formValues.region.label : ''}
                                            onInputChange={(event, newInputValue) => handleInputChange('region', newInputValue)}
                                            options={userMapping && userMapping.length > 0 ? regionsOptions : regions.map((region: any) => ({ label: region.label, value: region.value }))}
                                            disableClearable
                                            freeSolo={false}
                                            getOptionLabel={(option) => option.label || ''}
                                            renderInput={(params) => (<TextField {...params} label="Region" disabled={regionsOptions.length === 1} />)}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} lg={3} sx={{ mt: 2 }}>
                                    <Autocomplete fullWidth
                                        size="small"
                                        // value={formValues.district}
                                        value={ districtsOptions.length === 1 ? districtsOptions[0] : formValues.district}
                                        onChange={(event, newValue) => handleChange('district', newValue, 'district')}
                                        inputValue={formValues.district ? formValues.district.label : ''}
                                        onInputChange={(event, newInputValue) => handleInputChange('district', newInputValue)}
                                        options={
                                            userMapping && userMapping.length > 0 && userMapping.some((mapping: { district: any; }) => mapping.district)
                                            ? districtsOptions 
                                            : districts.map(district => ({ label: district.label, value: district.value })) 
                                        }
                                        disableClearable
                                        freeSolo={false}
                                        getOptionLabel={(option) => option.label || ''}
                                        renderInput={(params) => (<TextField {...params} label="District" disabled={districtsOptions.length === 1}/> )} />
                                </Grid>
                                {/* <Grid item xs={12} lg={3} sx={{ mt: 2 }}>
                                    <FormControl fullWidth required>
                                        <Autocomplete
                                            size='small'
                                            value={formValues.region}
                                            onChange={(event: any, newValue: any | null) => handleChange('region', newValue, 'region')}
                                            inputValue={formValues.region ? formValues.region.label : ''}
                                            onInputChange={(event, newInputValue) => handleInputChange('region', newInputValue)}
                                            options={userMapping && userMapping.length > 0 ? regionsOptions : regions.map((region: any) => ({ label: region.label, value: region.value }))}
                                            disableClearable
                                            freeSolo={false}
                                            getOptionLabel={(option) => option.label || ''}
                                            renderInput={(params) => (
                                                <TextField 
                                                    {...params} 
                                                    label="Region"
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} lg={3} sx={{ mt: 2 }}>
                                    <FormControl fullWidth required>
                                        <Autocomplete
                                            size='small'
                                            value={formValues.district}
                                            onChange={(event: any, newValue: any | null) => handleChange('district', newValue, 'district')}
                                            inputValue={formValues.district ? formValues.district.label : ''}
                                            onInputChange={(event, newInputValue) => handleInputChange('district', newInputValue)}
                                            options={userMapping && userMapping.length > 0 ? districtsOptions : districts.map((district: any) => ({ label: district.label, value: district.value }))}
                                            disableClearable
                                            freeSolo={false}
                                            getOptionLabel={(option) => option.label || ''}
                                            renderInput={(params) => (
                                                <TextField 
                                                    {...params} 
                                                    label="District"
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </Grid> */}
                                <Grid item xs={12} lg={3} sx={{ mt: 2 }}>
                                    <FormControl fullWidth required>
                                        <Autocomplete
                                            size='small'
                                            value={formValues.taluka}
                                            onChange={(event: any, newValue: string | null) => handleChange('taluka', newValue, 'taluka')}
                                            inputValue={formValues.taluka}
                                            onInputChange={(event, newInputValue) => handleInputChange('taluka', newInputValue)}
                                            options={talukas.map((taluka: any) => taluka.label)}
                                            disableClearable
                                            freeSolo={false}
                                            renderInput={(params) => (
                                                <TextField 
                                                    {...params} 
                                                    label="Taluka"
                                                    
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} lg={3} sx={{ mt: 2 }}>
                                    <Button onClick={handleSubmit} variant="contained" color="info" sx={{marginTop:'3px'}}>
                                        Filter Data
                                    </Button>
                                    <Button onClick={handleReset} variant="outlined" color="primary" sx={{marginTop:'3px', ml: 2}}>
                                        Reset
                                    </Button>
                                </Grid>
                            </Grid>
                            {error && (
                                <Grid item xs={12} textAlign={'left'} mt={2}>
                                    <Typography color="error" variant="body2" sx={{fontWeight:'400 !important'}}>{error}</Typography>
                                </Grid>
                            )}
                        </AccordionDetails>
                </Accordion>
                <Paper>
                    <Box sx={{ borderTopWidth: 1, borderRightWidth: 0, borderBottomWidth: 1, borderLeftWidth: 0, borderStyle: 'solid', borderColor: 'grey.400', px: 2, py: 1 }}>
                        <Grid container columnSpacing={3}>
                            <Grid item xs={12} lg={6}>
                                <Box sx={{ display: 'flex', gap:2 }}>
                                    {permissions.includes('Add') && (
                                        <Button variant='contained' startIcon={<Add />} onClick={handleOpenDialog}>Add Project</Button>
                                    )}
                                    <Button variant='contained' color="success" startIcon={<IosShareIcon />} onClick={handleProjectExport}>Export</Button> 
                                </Box>
                            </Grid>
                            <Grid item xs={12} lg={6} textAlign={'right'}>
                                <Box sx={{display:'flex', justifyContent:'flex-end', alignItems:'center'}}>
                                    <Button color="info" variant="contained"  title="Bulk Upload" onClick={() => sampleDownload()} style={{ cursor: 'pointer', marginRight:10,}}>
                                            <FileCopyIcon sx={{fontSize:'16px', mr:1}} /> Sample File
                                    </Button>
                                    <Button color="success" variant="contained"  title="Bulk Upload" onClick={() => handleOpenBulkUploadDialog()} style={{ cursor: 'pointer', marginRight:10,}}>
                                            <CloudUploadIcon sx={{fontSize:'16px', mr:1}} /> Bulk Upload
                                    </Button>
                                    <TextField sx={{width:'300px'}}
                                        label="Search"
                                        placeholder="Search here ..."
                                        variant="outlined"
                                        size="small"
                                        value={searchTerm}
                                        onChange={handleSearchChange}
                                    />
                                    <Button onClick={handleSearchReset} variant="contained" color="info" sx={{ ml: 2}}>
                                        Reset
                                    </Button>
                                </Box>
                            </Grid>                           
                        </Grid> 
                    </Box>
                    <TableContainer sx={{ maxHeight: 600 }}>
                        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size='medium' stickyHeader aria-label="sticky table">
                            <TableHeadList headCells={headCells} onSort={handleSort} sortBy={sortBy} sortOrder={sortOrder} />
                            {permissions.includes('List') ? (
                            <TableBody>
                                    {project.length > 0 ? (
                                        project.map((row, index) => (
                                    <TableRow key={row.id}>
                                        <StyledTableCell sx={{ whiteSpace: 'nowrap', borderRightWidth: 1, borderRightStyle: 'solid', borderRightColor: 'grey.300' }}>
                                            {index + 1 + page * rowsPerPage}
                                        </StyledTableCell>
                                        <StyledTableCell sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRightWidth: 1, borderRightStyle: 'solid', borderRightColor: 'grey.300' }}>
                                            {row.state_name}
                                        </StyledTableCell>
                                        <StyledTableCell sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRightWidth: 1, borderRightStyle: 'solid', borderRightColor: 'grey.300' }}>
                                            {row.company_name}
                                        </StyledTableCell>
                                        <StyledTableCell sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRightWidth: 1, borderRightStyle: 'solid', borderRightColor: 'grey.300' }}>
                                            {row.region_name}
                                        </StyledTableCell>
                                        <StyledTableCell sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRightWidth: 1, borderRightStyle: 'solid', borderRightColor: 'grey.300' }}>
                                            {row.district_name}
                                        </StyledTableCell>
                                        <StyledTableCell sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRightWidth: 1, borderRightStyle: 'solid', borderRightColor: 'grey.300' }}>
                                            {row.taluka_name}
                                        </StyledTableCell>
                                        <StyledTableCell sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRightWidth: 1, borderRightStyle: 'solid', borderRightColor: 'grey.300' }}>
                                            {row.project_id}
                                        </StyledTableCell>
                                        <StyledTableCell sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRightWidth: 1, borderRightStyle: 'solid', borderRightColor: 'grey.300' }}>
                                            {row.name}
                                        </StyledTableCell>
                                        <StyledTableCell sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRightWidth: 1, borderRightStyle: 'solid', borderRightColor: 'grey.300' }}>  
                                            <Button aria-describedby={id} onClick={(e) => handleClickPopover(e, row.id)}>
                                                <MoreVertIcon />
                                            </Button>
                                            {popoverRowId === row.id && (
                                                <Popover id={id} open={open} anchorEl={anchorEl} onClose={handleClosePopover} anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }}>
                                                    <Box sx={{display:'flex', flexDirection:'column', gap:2}}>
                                                        {permissions.includes('Edit') && (
                                                             <Box display={'flex'} justifyContent={'space-between'} >  
                                                                <Typography variant="h6" component="h6" fontWeight={600} color={"#434343"} fontSize={'15px'} mr={2}>Edit</Typography>
                                                                <Button size="small" variant="contained"  title="Edit" onClick={() => handleOpenEditDialog(row)} style={{ cursor: 'pointer', marginRight:10, width:'30px' }}>
                                                                        <ModeEditOutlinedIcon sx={{fontSize:'16px'}} />
                                                                </Button>
                                                            </Box>
                                                        )}
                                                        {permissions.includes('Delete') && (
                                                             <Box display={'flex'} justifyContent={'space-between'} >  
                                                                <Typography variant="h6" component="h6" fontWeight={600} color={"#434343"} fontSize={'15px'} mr={2}>Delete</Typography>
                                                                <Button size="small" color="error" variant="contained"  title="Delete" onClick={() => handleOpenDeleteDialog(row)} style={{ cursor: 'pointer', marginRight:10, width:'30px' }}>
                                                                    <DeleteOutlineOutlinedIcon sx={{fontSize:'16px'}} />
                                                                </Button>
                                                            </Box>
                                                        )}
                                                    </Box>
                                                </Popover> 
                                            )}                                            
                                       
                                        </StyledTableCell>
                                    </TableRow>
                                ))
                                ) : (
                                    <TableRow>
                                        <StyledTableCell colSpan={8} align="center">
                                            {loading ? (
                                                <CircularProgress size={24} />
                                            ) : (
                                                <Typography variant="body2" color="textSecondary">
                                                    No projects found.
                                                </Typography>
                                            )}
                                        </StyledTableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                            ) : (
                                <TableRow>
                                    <StyledTableCell colSpan={6} align="center" sx={{p:1}}>
                                        <BlockIcon sx={{fontSize:'40px', color:"#0288d1"}}/>
                                        <Typography variant="h5" color="#0288d1" fontWeight={300}>
                                           Sorry, you cannot view the page.
                                        </Typography>
                                        <Typography variant="body1" color="error" fontWeight={300} pt={1}>
                                           No permission to access this page
                                        </Typography>
                                    </StyledTableCell>
                                </TableRow>
                            )}
                        </Table>
                    </TableContainer>    
                    <TablePagination
                    rowsPerPageOptions={[10, 25, 50]}
                    component="div"
                    count={totalRows}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    />                 
                </Paper>
             </Box>
             <AddProjectDialog dialogOpen={dialogOpen}  handleCloseDialog={handleCloseDialog}  currentProject={currentProject} handleSuccess={handleSuccess}/>
             <DeleteProjectDialog dialogOpen={deleteDialogOpen} projectToDelete={projectToDelete} handleClose={handleCloseDeleteDialog} handleSuccess={handleSuccess} /> 
             <BulkUploadDialog dialogOpen={bulkUploadDialogOpen}  handleCloseDialog={handleCloseBulkUploadDialog} handleSuccess={handleSuccess}/>
        </>
    )
}
export default ProjectMaster