import { useEffect, useState } from 'react';
import { Button, DialogActions, DialogContent, DialogTitle, Dialog, Typography, TableContainer, Table, TableRow, TableCell, TableBody, TablePagination, Paper, CircularProgress, Grid, Box, IconButton, FormControl, TextField } from '@mui/material';
import { showSnackbar } from '../../../redux/reducer/snackbarSlice';
import { useDispatch } from 'react-redux';
import { StoreNotificationService } from '../../../utils/services/manage.service';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useLocation, useNavigate } from 'react-router-dom';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
interface NotificationDialogProps {
    dialogOpen: boolean;
    manageToNotification: any;
    handleCloseDialog: () => void;
    handleSuccess: () => void;
}
const NotificationDialog: React.FC<NotificationDialogProps> = ({ dialogOpen, manageToNotification, handleCloseDialog, handleSuccess }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [fileNames, setFileNames] = useState<string[]>([]);
    const [fileName, setFileName] = useState<string | null>(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const NotificationFormik = useFormik({
        initialValues: {           
            Remark: '',
            // file:[],
            attachments:null
        },
        validationSchema: Yup.object({            
            Remark: Yup.string().trim().required('Remark is required'),
        }),        
        onSubmit: async (values, { resetForm }) => {
            setLoading(true);
            const formData = new FormData();     
            formData.append('LetterId', manageToNotification.id);
            formData.append('Remark', values.Remark);  
            if (values.attachments) {
                formData.append('attachments', values.attachments);
            }         
            // if (values.file) {
            //     for (let i = 0; i < values.file.length; i++) {
            //         formData.append('file[]', values.file[i]);
            //     }
            // }
            const { data, code } = await StoreNotificationService(formData);
            if (code === 200) {
                resetForm();
                dispatch(showSnackbar({ message:  'Remark Added successfully', type: 'success', open: true }));
                navigate('/manage');
            } else {
                dispatch(showSnackbar({ message: data || 'Something went wrong. Please try again later.', type: 'error', open: true }));                
            }
            setLoading(false);            
        },
    });   
    // const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     if (event.currentTarget.files) {
    //       const selectedFiles = Array.from(event.currentTarget.files).map(file => file.name);
    //       setFileNames(selectedFiles);
    //       NotificationFormik.setFieldValue('file', event.currentTarget.files);
    //     }
    // };
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.currentTarget.files && event.currentTarget.files[0]) {
            const selectedFile = event.currentTarget.files[0].name;
            setFileName(selectedFile); // Save the name of the single selected file
            NotificationFormik.setFieldValue('attachments', event.currentTarget.files[0]); // Only set one file
        }
    };
    const handleClose = () => {
        NotificationFormik.resetForm();
        handleCloseDialog();
    };
    return (
        <>
            <Dialog fullWidth  maxWidth="sm" open={dialogOpen} onClose={handleClose}  PaperProps={{ component: 'form', onSubmit: NotificationFormik.handleSubmit }}>
                <DialogTitle component="div" sx={{ fontWeight: 'bold', fontSize: '20px', color: 'grey.800', position: 'sticky', top: '0', left: '0', right: '0', zIndex: '99', backgroundColor: '#fff',
                        borderBottom: '1px solid #ccc', p: '10px 25px', mb:2 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Grid container sx={{ alignItems: 'center' }}>
                            <Grid item xs={12} md={10}>
                                <Typography variant="h4">Notification</Typography>
                            </Grid>
                            <Grid item xs={12} md={2} textAlign={"end"}>
                                <IconButton onClick={handleClose}>
                                    <CloseIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Grid container columnSpacing={2}>
                        <Grid item xs={12} lg={6} sx={{ mt: 2 }}>
                            <FormControl fullWidth required>
                                <TextField sx={{'& .MuiInputBase-root': {padding: '0px'}}}
                                    autoComplete="off"
                                    fullWidth
                                    label="Remark*"
                                    variant="outlined"
                                    size="small"
                                    multiline 
                                    {...NotificationFormik.getFieldProps('Remark')}
                                    name="Remark"  
                                    error={NotificationFormik.touched.Remark && Boolean(NotificationFormik.errors.Remark)}
                                    helperText={NotificationFormik.touched.Remark && NotificationFormik.errors.Remark}
                                />
                            </FormControl>
                        </Grid>                        
                        <Grid item xs={12} lg={6} sx={{ mt: 2 }}>
                            <FormControl fullWidth required>
                                <Box display="block" alignItems="center">
                                    <Box position="relative">
                                        <input
                                            id="file-upload"
                                            type="file"
                                            name="attachments"
                                            style={{ display: 'none' }}
                                            onChange={handleFileChange}
                                            multiple 
                                        />
                                        <label htmlFor="file-upload">
                                            <Button variant="outlined" color="inherit" component="span" fullWidth>
                                                <InsertDriveFileIcon sx={{ mr: 1 }} /> Upload Files
                                            </Button>
                                        </label>
                                    </Box>
                                    <Box mt={2}>
                                    {fileName && (
                                        <Typography variant="body2">
                                            {fileName}
                                        </Typography>
                                    )}
                                    </Box>
                                </Box>
                            </FormControl>
                        </Grid>  
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="outlined" color="primary"> Cancel</Button>
                    <Button variant='contained' type="submit" disabled={loading} color='info'>Submit</Button>                 
                </DialogActions>
            </Dialog>
        </>
    )
}
 export default NotificationDialog